<template>
  <section class="additional-info" data-test="additional-info">
    <chb-section>
      <h3 class="font--display-2" data-test="section-title">
        {{ labels.sectionTitle }}
      </h3>
    </chb-section>
    <chb-section class="additional-info--content">
      <div class="additional-info--content_item" data-test="enrolment-request">
        <p>{{ labels.enrolmentRequest }}</p>
        <p>{{ email || "" }}</p>
      </div>
      <div class="additional-info--content_item" data-test="power-of-attorney">
        <p>{{ labels.powerOfAttorney }}</p>
        <p>
          <span v-if="showDownloadTemplate">
            <a
              :href="poaViewUrl"
              target="_blank"
              data-test="view-template"
              rel="noopener noreferrer"
              >{{ labels.viewTemplate }}
              <i class="icon mi-20px mi-file" aria-hidden="true"></i></a
          ></span>
          <span
            ><a
              :href="modifyPoaUrl"
              target="_blank"
              data-test="modify-template"
              rel="noopener noreferrer"
              >{{ labels.modifyTemplate }}
              <i class="icon mi-20px mi-pencil" aria-hidden="true"></i></a
          ></span>
        </p>
      </div>
    </chb-section>
  </section>
</template>

<script>
import ChbSection from "@/components/common/ChbSection.vue";
import i18nFilter from "@/filters/i18nFilter";
import { constants } from "@/utilities/CONSTANTS";
import { mapState } from "vuex";

export default {
  name: "AdditionalInfo",
  components: {
    ChbSection
  },
  data() {
    return {
      modifyPoaUrl: constants.MODIFY_POA_TEMPLATE_URL
    };
  },
  computed: {
    ...mapState({
      additionalInformation: state => state.additionalInformation
    }),
    labels() {
      const prefix =
        "chbAdmin.editPage.form.editCountry.onboarding.additionalInformation";
      return {
        sectionTitle: i18nFilter(`${prefix}.sectionTitle`),
        enrolmentRequest: i18nFilter(`${prefix}.enrolmentRequestEmail`),
        powerOfAttorney: i18nFilter(`${prefix}.powerOfAttorney`),
        viewTemplate: i18nFilter(`${prefix}.viewTemplate`),
        modifyTemplate: i18nFilter(`${prefix}.modifyTemplate`)
      };
    },
    email() {
      return this.additionalInformation?.enrollmentEmail;
    },
    showDownloadTemplate() {
      return this.additionalInformation?.templates?.length;
    },
    poaViewUrl() {
      return this.additionalInformation?.templates[0]?.url;
    }
  }
};
</script>

<style scoped lang="scss">
.additional-info {
  margin-top: 56px;
  &--content {
    padding: 16px;
    margin-top: 24px;
    background: #f7f7f7;
    &_item {
      margin-bottom: 32px;

      a {
        color: #0073ab;
        margin-right: 20px;
        display: flex;
        align-items: center;
        i {
          margin-left: 4px;
        }
      }
      p:first-of-type {
        margin: 0;
        color: #878787;
        display: block;
      }
      p:not(first-of-type) {
        margin-top: 4px;
        display: inline-flex;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
